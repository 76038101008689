import { Authenticator } from '@aws-amplify/ui-react';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/authentication/Login';
import RequireAdminPermissions from './components/authentication/RequireAdminPermissions';
import RequireAuth from './components/authentication/RequireAuth';
import NavBar from './components/navigation/NavBar';
import Home from './components/pages/HomePage/Home';
import Music from './components/pages/MusicPage/Music';
import MusicAddRequest from './components/pages/MusicPage/MusicAddRequest';
import AdminPage from './components/pages/AdminPage/AdminPage';
import { getTheme } from './themeUtility';
import Forbidden from './components/pages/ForbiddenPage/Forbidden';
import Footer from './components/footer/Footer';
import AdminApplicationPage from './components/pages/AdminPage/AdminApplication';
import ForumHome from './components/pages/ForumPage/ForumHome';
import ForumTopic from './components/pages/ForumPage/ForumTopic';

function App({ theme }) {
  const lightTheme = createTheme({
    palette: {
      mode: 'light'
    }
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  const currentTheme = getTheme(theme) === 'dark' ? darkTheme : lightTheme;

  const themeSwitchOn = getTheme(theme) === 'light';

  return (
    <Authenticator.Provider>
      <ThemeProvider theme={currentTheme}>
        <Router history={history}>
          <CssBaseline>
            <NavBar themeSwitchOn={themeSwitchOn} />
            <Box paddingBottom="6rem">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/music-database" element={<Music />} />
                <Route
                  path="/music-database/add-request"
                  element={
                    <RequireAuth>
                      <MusicAddRequest />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <RequireAdminPermissions>
                      <AdminPage />
                    </RequireAdminPermissions>
                  }
                />
                <Route
                  path="/admin-application"
                  element={
                    <RequireAuth>
                      <AdminApplicationPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/forum"
                  element={
                    <RequireAuth>
                      <ForumHome />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/forum/topic/:id"
                  element={
                    <RequireAuth>
                      <ForumTopic />
                    </RequireAuth>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/forbidden" element={<Forbidden />} />
              </Routes>
            </Box>
            <Footer />
          </CssBaseline>
        </Router>
      </ThemeProvider>
    </Authenticator.Provider>
  );
}

App.propTypes = {
  theme: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  theme: (state.theme || {}).currentTheme
});

export default connect(mapStateToProps)(App);
