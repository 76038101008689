/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cloud_logic_custom: [
    {
      name: 'drumsmithAPI',
      endpoint: 'https://amyvkohy16.execute-api.us-west-2.amazonaws.com/staging',
      region: 'us-west-2'
    }
  ],
  aws_appsync_graphqlEndpoint:
    'https://ii7n6eschfbv3bmkfid3f75xsi.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: true,
  aws_cognito_identity_pool_id: 'us-west-2:efac7c92-08b1-4ca8-bdf3-c9cfaf1c1581',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_FVfZYziHi',
  aws_user_pools_web_client_id: '4i8mfr399r75qe04iijvnahv8j',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 12,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
      'REQUIRES_UPPERCASE'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_dynamodb_all_tables_region: 'us-west-2',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'drumsmithMusicRequestsDynamoDB-staging',
      region: 'us-west-2'
    },
    {
      tableName: 'drusmithMusicDynamoDB-staging',
      region: 'us-west-2'
    },
    {
      tableName: 'drumsmithAdminApplications-staging',
      region: 'us-west-2'
    },
    {
      tableName: 'drumsmithExerciseDynamoDB-staging',
      region: 'us-west-2'
    },
    {
      tableName: 'drumsmithExerciseRequestsDynamoDB-staging',
      region: 'us-west-2'
    }
  ],
  aws_user_files_s3_bucket: 'drumsmith07989a941fe649fd9d3282e781605502220939-staging',
  aws_user_files_s3_bucket_region: 'us-west-2'
};

export default awsmobile;
