import { useEffect } from 'react';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  let from = location.state?.from?.pathname || '/';

  const addRequestAuthReason =
    'You need to have an account and be logged in in order to request an addition to the database!';

  const adminPageAuthReason =
    "It seems you're trying to access the Admin page without being logged in! Please log in and try again";

  const profilePageAuthReason =
    'You need to have an account and be logged in in order to view your profile!';

  const adminApplicationPageAuthReason =
    'You need to have an account and be logged in in order to apply to be an admin!';

  const forumAuthReason =
    'You need to have an account and be logged in in order to view the Forum!';

  const reasonMap = {
    '/': '',
    '/music-database/add-request': addRequestAuthReason,
    '/admin': adminPageAuthReason,
    '/profile': profilePageAuthReason,
    '/admin-application': adminApplicationPageAuthReason,
    '/forum': forumAuthReason
  };

  let reasonElement =
    reasonMap[from] === '' && location.state?.redirect ? (
      ''
    ) : (
      <Grid item pt={2}>
        <h3>{reasonMap[from]}</h3>
      </Grid>
    );

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="flex-end">
      <Grid item pt={1}>
        <h1>Log In</h1>
      </Grid>
      {reasonElement}
      <Grid item pt={5}>
        <Authenticator />
      </Grid>
    </Grid>
  );
};

export default Login;
