import { BottomNavigation, Button, Grid, Typography } from '@mui/material';
import _ from 'underscore';
import './Footer.css';

const Footer = () => {
  const callsToAction = ['Donate!', 'Buy me a coffee!', 'Support DrumSmith!'];
  const navigateToStripe = () => {
    if (window.location.host === 'localhost:3000') {
      window.open('https://donate.stripe.com/test_4gwcOo0pfdcndlS8ww', '_blank').focus();
    } else {
      window.open('https://donate.stripe.com/4gwbIZeBK9RY52M4gg', '_blank').focus();
    }
  };

  return (
    <BottomNavigation
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, paddingBottom: '1rem' }}>
      <Grid container diection="row" alignItems="left" justifyContent="left">
        <Typography sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
          Made with &#10084; in Seattle, WA.
        </Typography>
      </Grid>
      <Grid container direction="row" alignItems="right" justifyContent="right">
        <Button
          variant="contained"
          className="rainbow-button"
          sx={{ marginBottom: '1rem', marginRight: '1rem', color: '#0d1a26' }}
          onClick={navigateToStripe}>
          <strong>{_.sample(callsToAction).toUpperCase()}</strong>
        </Button>
      </Grid>
    </BottomNavigation>
  );
};

export default Footer;
