import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { Amplify, Cache } from 'aws-amplify';
import awsconfig from './aws-exports';

if (window.location.host.includes('localhost')) {
  window.STAGE = 'DEV';
}

Amplify.configure(awsconfig);
Cache.configure({
  defaultTTL: 30 * 60000,
  storage: window.sessionStorage
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
