export const getTheme = (theme) => {
  const themeInLocalStorage = localStorage.getItem('theme');

  let currentTheme = null;

  if (themeInLocalStorage !== null) {
    currentTheme = themeInLocalStorage;
  } else {
    currentTheme = theme;
  }

  return currentTheme;
};
