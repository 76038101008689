import {
  Button,
  Grid,
  Alert,
  IconButton,
  AlertTitle,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  Skeleton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ErrorLoading from '../../error/ErrorLoading';
import { API, Cache, Storage } from 'aws-amplify';
import { constants } from '../../../ApiConstants';
import _ from 'underscore';
import CloseIcon from '@mui/icons-material/Close';
import ALERT_CONSTANTS from '../../alert/AlertConstants';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Music = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const userPayload = user?.getSignInUserSession()?.getAccessToken().payload;
  const userGroups = userPayload ? userPayload['cognito:groups'] : [];
  const isUserAdmin = userGroups?.includes('Admin');

  const [musicData, setMusicData] = useState([]);
  const [musicLoading, setMusicLoading] = useState(true);
  const [musicLoadError, setMusicLoadError] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [musicDeleted, setMusicDeleted] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowObject, setRowObject] = useState({});
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState('');

  useEffect(() => {
    const data = Cache.getItem('musicData');
    if (_.isUndefined(data) || _.isNull(data)) {
      console.log('Falling back to remote, unable to retrieve entries from Cache');
      API.get(constants.API_NAME, '/music').then((r) => {
        const items = r.Items;
        setMusicData(items);
        Cache.setItem('musicData', items);
      });
    } else {
      setMusicData(data);
    }

    if (musicData.error) {
      setMusicLoadError(true);
      setAlertType(ALERT_CONSTANTS.ALERT_TYPE.ERROR);
      setAlertMessage('Unable to load music database! Please try again!');
      console.error(musicData.error);
    }
    setMusicLoading(false);
  }, [musicDeleted]);

  useEffect(() => {
    setMusicLoading(musicData.length === 0);
  }, [musicData]);

  const renderPdf = async (fileName) => {
    const fileBlob = await Storage.get(fileName, {
      level: 'public',
      download: true,
      contentType: 'application/pdf'
    });
    const url = window.URL.createObjectURL(fileBlob.Body);
    setPdfFile(url);
    setPdfModalOpen(true);
  };

  const downloadFile = async (fileName, year, group, instrument, showName) => {
    const fileBlob = await Storage.get(fileName, {
      level: 'public',
      download: true,
      contentType: 'application/pdf'
    });

    const url = window.URL.createObjectURL(fileBlob.Body);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${group}-${year}-${showName}-${instrument}.pdf`;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  };

  const deleteDbEntry = (row) => {
    setDeleteModalOpen(false);
    const id = row.id;
    const fileName = row.downloadLink;

    const init = {
      body: {
        id
      }
    };

    API.post(constants.API_NAME, '/music-remover', init).then((r) => {
      if (r.error) {
        setAlertType(ALERT_CONSTANTS.ALERT_TYPE.ERROR);
        setAlertMessage('Unable to delete Item from Music DB!');
      } else {
        try {
          Storage.remove(fileName, {
            level: 'public'
          });
          setAlertType(ALERT_CONSTANTS.ALERT_TYPE.SUCCESS);
          setAlertMessage(`Successfully deleted ${row.showName}-${row.movement} from Music DB!`);
          setMusicDeleted(true);
          setMusicData({});
        } catch (e) {
          setAlertType(ALERT_CONSTANTS.ALERT_TYPE.ERROR);
          setAlertMessage('Unable to remove File from Music DB!');
          console.error(e);
        }
      }
    });
    Cache.removeItem('musicData');
  };

  const renderViewButton = (params) => {
    return (
      <strong>
        <Button variant="contained" size="small" onClick={() => renderPdf(params.row.downloadLink)}>
          View
        </Button>
      </strong>
    );
  };

  const renderDownloadButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            downloadFile(
              params.row.downloadLink,
              params.row.year,
              params.row.group,
              params.row.instrument,
              params.row.showName
            )
          }>
          Download
        </Button>
      </strong>
    );
  };

  const renderDeleteButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: '#d10015', color: 'white' }}
          onClick={() => {
            setDeleteModalOpen(true);
            setRowObject(params.row);
          }}>
          Delete
        </Button>
      </strong>
    );
  };

  const columnVisibilityModel = {
    id: false
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'year', headerName: 'Year', minWidth: 75, flex: 1 },
    { field: 'league', headerName: 'League', minWidth: 75, flex: 1 },
    { field: 'groupClass', headerName: 'Class', minWidth: 75, flex: 1 },
    { field: 'group', headerName: 'Group', minWidth: 125, flex: 1 },
    { field: 'instrument', headerName: 'Instrument', minWidth: 100, flex: 1 },
    { field: 'showName', headerName: 'Show Name', minWidth: 150, flex: 1 },
    { field: 'movement', headerName: 'Movement', minWidth: 125, flex: 1 },
    {
      field: 'viewModal',
      headerName: 'View',
      minWidth: 75,
      flex: 1,
      renderCell: renderViewButton
    },
    {
      field: 'downloadLink',
      headerName: 'Download',
      minWidth: 125,
      flex: 1,
      renderCell: renderDownloadButton
    }
  ];

  if (isUserAdmin) {
    columns.push({
      field: 'delete',
      headerName: 'Delete',
      minWidth: 125,
      flex: 1,
      renderCell: renderDeleteButton
    });
  }

  let musicComponentToRender;
  let dataGridDisplayProps = {
    paddingTop: '2rem',
    paddingBottom: '2rem'
  };
  if (musicLoadError) {
    musicComponentToRender = <ErrorLoading />;
  } else {
    dataGridDisplayProps = {
      ...dataGridDisplayProps,
      width: '60%'
    };
    musicComponentToRender = musicLoading ? (
      <Skeleton variant="rounded" animation="wave" height={'15rem'} width={'60rem'} />
    ) : (
      <DataGrid rows={musicData} columns={columns} columnVisibilityModel={columnVisibilityModel} />
    );
  }

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  return (
    <>
      <Collapse in={_.isEmpty(alertMessage)}>
        <Alert
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
              {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="filled">
          <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
          {alertMessage}
        </Alert>
      </Collapse>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <h1>Music Transcription Database</h1>
        </Grid>
        <Grid item>
          <h4>
            The below table contains all the transcriptions currently available on DrumSmith. You
            can search by or sort any column to find exactly what you&apos;re looking for!
          </h4>
        </Grid>
        <Grid item style={dataGridDisplayProps}>
          {musicComponentToRender}
        </Grid>
        <Grid container item direction="column" alignItems="center" justifyContent="center">
          <h3>Want to add more music to the database?</h3>
          <Button variant="outlined" onClick={() => navigate('/music-database/add-request')}>
            Click Here!
          </Button>
        </Grid>
      </Grid>
      <Dialog open={pdfModalOpen} onClose={() => setPdfModalOpen(false)} maxWidth={false}>
        <iframe src={pdfFile} style={{ width: '90vw', height: '85vw', border: 0 }} />
      </Dialog>
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          You are about to permanently delete an item from the music database! This action is not
          reversible... Are you sure you want to do this?
        </DialogContent>
        <DialogContent>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
              <strong>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#05ba02' }}
                  onClick={() => deleteDbEntry(rowObject)}>
                  Yes
                </Button>
              </strong>
            </Grid>
            <Grid item>
              <strong>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#d10015', color: 'white' }}
                  onClick={() => setDeleteModalOpen(false)}>
                  No
                </Button>
              </strong>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Music;
