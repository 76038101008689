/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCommentByTopicId = /* GraphQL */ `
  subscription OnCreateCommentByTopicId($topicId: String!) {
    onCreateCommentByTopicId(topicId: $topicId) {
      id
      topicId
      content
      topic {
        id
        title
        content
        createdAt
        updatedAt
        owner
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCommentByTopicId = /* GraphQL */ `
  subscription OnDeleteCommentByTopicId($topicId: String!) {
    onDeleteCommentByTopicId(topicId: $topicId) {
      id
      topicId
      content
      topic {
        id
        title
        content
        createdAt
        updatedAt
        owner
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCommentByTopicId = /* GraphQL */ `
  subscription OnUpdateCommentByTopicId($topicId: String!) {
    onUpdateCommentByTopicId(topicId: $topicId) {
      id
      topicId
      content
      topic {
        id
        title
        content
        createdAt
        updatedAt
        owner
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic(
    $filter: ModelSubscriptionTopicFilterInput
    $owner: String
  ) {
    onCreateTopic(filter: $filter, owner: $owner) {
      id
      title
      content
      comments {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic(
    $filter: ModelSubscriptionTopicFilterInput
    $owner: String
  ) {
    onUpdateTopic(filter: $filter, owner: $owner) {
      id
      title
      content
      comments {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic(
    $filter: ModelSubscriptionTopicFilterInput
    $owner: String
  ) {
    onDeleteTopic(filter: $filter, owner: $owner) {
      id
      title
      content
      comments {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onCreateComment(filter: $filter, owner: $owner) {
      id
      topicId
      content
      topic {
        id
        title
        content
        createdAt
        updatedAt
        owner
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onUpdateComment(filter: $filter, owner: $owner) {
      id
      topicId
      content
      topic {
        id
        title
        content
        createdAt
        updatedAt
        owner
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onDeleteComment(filter: $filter, owner: $owner) {
      id
      topicId
      content
      topic {
        id
        title
        content
        createdAt
        updatedAt
        owner
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport(
    $filter: ModelSubscriptionReportFilterInput
    $owner: String
  ) {
    onCreateReport(filter: $filter, owner: $owner) {
      id
      resourceId
      resourceType
      reason
      context
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport(
    $filter: ModelSubscriptionReportFilterInput
    $owner: String
  ) {
    onUpdateReport(filter: $filter, owner: $owner) {
      id
      resourceId
      resourceType
      reason
      context
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport(
    $filter: ModelSubscriptionReportFilterInput
    $owner: String
  ) {
    onDeleteReport(filter: $filter, owner: $owner) {
      id
      resourceId
      resourceType
      reason
      context
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
