import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import _ from 'underscore';
import { ReportingReasons } from '../../../constants/ReportingConstants';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

const ReportingModal = ({
  modalTitle,
  modalContent,
  reportingReason,
  onReportingReasonChange,
  reportingContext,
  onReportingContextChange,
  open,
  onClose,
  onReport,
  alertType,
  alertMessage,
  removeAlert
}) => {
  let alert = '';
  if (!_.isEmpty(alertMessage)) {
    alert = (
      <Grid item>
        <Collapse in={() => _.isEmpty(alertMessage)}>
          <Alert
            severity={alertType}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
                {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
              </IconButton>
            }
            sx={{ mb: 2 }}
            variant="filled">
            <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
            {alertMessage}
          </Alert>
        </Collapse>
      </Grid>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>{modalContent}</DialogContent>
      <DialogContent>
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={4}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="reporting-reason-label">Reason</InputLabel>
              <Select
                labelId="reporting-reason-label"
                id="reporting-reason-select"
                value={reportingReason}
                label="Reason"
                onChange={onReportingReasonChange}
                error={_.isEmpty(reportingReason)}
                autoWidth
                sx={{ minWidth: '8rem' }}>
                {ReportingReasons.map(({ reason, displayName }) => {
                  return (
                    <MenuItem value={reason} key={`reason-${reason}`}>
                      {displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              label="Other information"
              multiline
              fullWidth
              variant="outlined"
              value={reportingContext}
              onChange={onReportingContextChange}
            />
          </Grid>
          <Grid item>
            <strong>
              <Button variant="contained" onClick={onReport}>
                Report
              </Button>
            </strong>
          </Grid>
          {alert}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ReportingModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalContent: PropTypes.string.isRequired,
  reportingReason: PropTypes.string.isRequired,
  onReportingReasonChange: PropTypes.func.isRequired,
  reportingContext: PropTypes.string.isRequired,
  onReportingContextChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
  alertType: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
  removeAlert: PropTypes.func.isRequired
};

export default ReportingModal;
