import { SET_THEME } from './theme.types';

const INITIAL_STATE = {
  currentTheme: 'dark'
};

const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_THEME:
      return { ...state, currentTheme: action.data };
    default:
      return state;
  }
};

export default themeReducer;
