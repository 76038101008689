import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'underscore';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { cleanString } from '../../../utilities/CensoringUtility';
import * as mutations from '../../../graphql/mutations';
import { ReportingResourceTypes } from '../../../constants/ReportingConstants';
import ReportingModal from './ReportingModal';
import alertConstants from '../../alert/AlertConstants';

const ForumComment = ({ comment }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const userPayload = user?.getSignInUserSession()?.getAccessToken().payload;
  const userGroups = userPayload ? userPayload['cognito:groups'] : [];
  const isUserAdmin = userGroups?.includes('Admin');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editContent, setEditContent] = useState('');
  const [reportingReason, setReportingReason] = useState('');
  const [reportingContext, setReportingContext] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  const editComment = async () => {
    if (_.isEmpty(editContent)) {
      return;
    }
    const updatedComment = await API.graphql({
      query: mutations.updateComment,
      variables: { input: { id: comment.id, content: cleanString(editContent) } }
    });
    console.log(`Edited Comment: ${updatedComment}`);
    setEditMode(false);
  };

  let editButton = '';
  if (_.isEqual(user.username, comment.owner)) {
    if (editMode) {
      editButton = (
        <>
          <IconButton
            edge="end"
            aria-label="edit"
            sx={{ marginRight: '1rem' }}
            onClick={editComment}>
            <CheckIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="edit"
            sx={{ marginRight: '1rem' }}
            onClick={() => setEditMode(false)}>
            <CloseIcon />
          </IconButton>
        </>
      );
    } else {
      editButton = (
        <IconButton
          edge="end"
          aria-label="edit"
          sx={{ marginRight: '1rem' }}
          onClick={() => {
            setEditMode(true);
            setEditContent(comment.content);
          }}>
          <EditIcon />
        </IconButton>
      );
    }
  }

  const deleteComment = async () => {
    try {
      const deletedComment = await API.graphql({
        query: mutations.deleteComment,
        variables: { input: { id: comment.id } }
      });
      console.log(`Deleted Comment: ${deletedComment}`);
      setAlertType(alertConstants.ALERT_TYPE.SUCCESS);
      setAlertMessage('Successfully Deleted Comment!');
    } catch (e) {
      setAlertType(alertConstants.ALERT_TYPE.ERROR);
      setAlertMessage('Unable to Deleted Comment! Please Try Again!');
      console.error(e);
    }
  };

  let deleteButton = '';
  if (isUserAdmin || _.isEqual(user.username, comment.owner)) {
    deleteButton = (
      <IconButton
        edge="end"
        aria-label="delete"
        sx={{ marginRight: '1rem' }}
        onClick={() => setDeleteModalOpen(true)}>
        <DeleteIcon />
      </IconButton>
    );
  }

  const reportComment = async () => {
    if (_.isEmpty(reportingReason)) {
      setAlertType(alertConstants.ALERT_TYPE.ERROR);
      setAlertMessage('Unable to report comment without a reason! Please try again!');
      return;
    }
    try {
      const report = await API.graphql({
        query: mutations.createReport,
        variables: {
          input: {
            resourceId: comment.id,
            resourceType: ReportingResourceTypes.COMMENT,
            reason: reportingReason,
            context: reportingContext
          }
        }
      });
      console.log(report);
      setAlertType(alertConstants.ALERT_TYPE.SUCCESS);
      setAlertMessage('Successfully Reported Comment!');
    } catch (e) {
      setAlertType(alertConstants.ALERT_TYPE.ERROR);
      setAlertMessage('Unable to Report Comment! Please Try Again!');
      console.error(e);
    }
  };

  let reportButton = (
    <IconButton edge="end" aria-label="report" onClick={() => setReportModalOpen(true)}>
      <DangerousIcon />
    </IconButton>
  );
  if (_.isEqual(user.username, comment.owner)) {
    reportButton = '';
  }

  let availableActions = (
    <>
      {editButton}
      {deleteButton}
      {reportButton}
    </>
  );

  let commentText = (
    <ListItemText
      primary={`${comment.owner} - ${new Date(comment.updatedAt)
        .toLocaleString()
        .replaceAll(',', '')}`}
      secondary={comment.content}
    />
  );
  if (editMode) {
    commentText = (
      <TextField
        fullWidth
        required
        variant="outlined"
        value={editContent}
        onChange={(e) => setEditContent(e.target.value)}
        error={_.isEmpty(editContent)}
        sx={{ marginRight: '1rem' }}
      />
    );
  }

  let alert = '';
  if (!_.isEmpty(alertMessage)) {
    alert = (
      <Grid item>
        <Collapse in={() => _.isEmpty(alertMessage)}>
          <Alert
            severity={alertType}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
                {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
              </IconButton>
            }
            sx={{ mb: 2 }}
            variant="filled">
            <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
            {alertMessage}
          </Alert>
        </Collapse>
      </Grid>
    );
  }

  let modalContent = '';
  if (deleteModalOpen) {
    modalContent = (
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        maxWidth={false}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          You are about to permanently delete this comment! This action is not reversible... Are you
          sure?
        </DialogContent>
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}>
              <Grid item>
                <strong>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#05ba02' }}
                    onClick={() => {
                      deleteComment();
                      setTimeout(() => {
                        setDeleteModalOpen(false);
                        removeAlert();
                      }, 3000);
                    }}>
                    Yes
                  </Button>
                </strong>
              </Grid>
              <Grid item>
                <strong>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#d10015', color: 'white' }}
                    onClick={() => setDeleteModalOpen(false)}>
                    No
                  </Button>
                </strong>
              </Grid>
            </Grid>
            {alert}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
  if (reportModalOpen) {
    modalContent = (
      <ReportingModal
        modalTitle="Report Comment"
        modalContent="What would you like to report this comment for?"
        reportingReason={reportingReason}
        reportingContext={reportingContext}
        onReportingReasonChange={(e) => setReportingReason(e.target.value)}
        onReportingContextChange={(e) => setReportingContext(e.target.value)}
        open={reportModalOpen}
        onClose={() => {
          setReportModalOpen(false);
          setReportingReason('');
          setReportingContext('');
          setAlertType('');
          setAlertMessage('');
        }}
        onReport={() => {
          reportComment();
          setTimeout(() => {
            setReportModalOpen(false);
            setReportingReason('');
            setReportingContext('');
            removeAlert();
          }, 3000);
        }}
        alertType={alertType}
        alertMessage={alertMessage}
        removeAlert={removeAlert}
      />
    );
  }

  return (
    <>
      {modalContent}
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={comment.owner} src="">
              {comment.owner.charAt(0).toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          {commentText}
          {availableActions}
        </ListItem>
      </List>
      <Divider />
    </>
  );
};

ForumComment.propTypes = {
  comment: PropTypes.object.isRequired
};

export default ForumComment;
