import { React, useEffect, useState } from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Button,
  Avatar
} from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTheme } from '../../redux/theme/theme.actions';
import PropTypes from 'prop-types';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

const navTitles = [
  {
    title: 'Home',
    link: '/'
  },
  {
    title: 'Forum',
    link: '/forum'
  },
  {
    title: 'Music Database',
    link: '/music-database'
  }
];

const adminNavItems = [
  {
    title: 'Admin',
    link: '/admin'
  }
];

const profileItems = [
  {
    title: 'Log Out',
    link: ''
  }
];

function NavBar({ themeSwitchOn, storeCurrentTheme }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [themeChecked, setThemeChecked] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  const userPayload = user?.getSignInUserSession()?.getAccessToken().payload;
  const userGroups = userPayload ? userPayload['cognito:groups'] : [];
  const isUserAdmin = userGroups?.includes('Admin');

  useEffect(() => {
    setThemeChecked(themeSwitchOn);
  }, [themeSwitchOn]);

  useEffect(() => {
    if (themeChecked) {
      storeCurrentTheme('light');
      localStorage.setItem('theme', 'light');
    } else {
      storeCurrentTheme('dark');
      localStorage.setItem('theme', 'dark');
    }
  }, [themeChecked]);

  const handleNavMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const themeSwitchHandler = (event) => {
    setThemeChecked(event.target.checked);
  };

  const open = Boolean(anchorEl);
  const profileOpen = Boolean(profileAnchorEl);

  const signInButton = (
    <Button variant="contained" onClick={() => navigate('/login')}>
      Log In
    </Button>
  );

  const onSignOutClick = async () => {
    await Auth.signOut();
    location.reload();
  };

  const userProfile = (
    <>
      <IconButton
        id="profile-menu-button"
        aria-controls={profileOpen ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={profileOpen ? 'true' : undefined}
        onClick={handleProfileMenuClick}>
        <Avatar id="profile-avatar">{user?.username?.charAt(0).toUpperCase()}</Avatar>
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={profileAnchorEl}
        open={profileOpen}
        onClose={handleProfileMenuClose}
        MenuListProps={{
          'aria-labelledby': 'profile-menu-button'
        }}>
        {profileItems.map(({ title, link }) => {
          if (title === 'Log Out') {
            return (
              <MenuItem onClick={() => onSignOutClick()} key={title}>
                {title}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem onClick={() => navigate(link)} key={title}>
                {title}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            id="nav-menu-button"
            aria-controls={open ? 'nav-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleNavMenuClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="nav-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleNavMenuClose}
            MenuListProps={{
              'aria-labelledby': 'nav-menu-button'
            }}>
            {navTitles.map(({ title, link }) => {
              return (
                <MenuItem onClick={() => navigate(link)} key={title}>
                  {title}
                </MenuItem>
              );
            })}
            {isUserAdmin
              ? adminNavItems.map(({ title, link }) => {
                  return (
                    <MenuItem onClick={() => navigate(link)} key={title}>
                      {title}
                    </MenuItem>
                  );
                })
              : ''}
          </Menu>
          <DarkModeIcon />
          <Switch checked={themeChecked} onChange={themeSwitchHandler} />
          <LightModeIcon />
          <Box marginLeft="auto">{user ? userProfile : signInButton}</Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

NavBar.propTypes = {
  themeSwitchOn: PropTypes.bool.isRequired,
  storeCurrentTheme: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  storeCurrentTheme: (theme) => dispatch(setTheme(theme))
});

export default connect(null, mapDispatchToProps)(NavBar);
