export const ReportingReasons = [
  { reason: 'HARASSMENT', displayName: 'Harassment' },
  { reason: 'THREATENING_BEHAVIOR', displayName: 'Threatening or Violent Behavior' },
  { reason: 'HATE_SPEECH', displayName: 'Hate Speech' },
  { reason: 'MINOR_ABUSE_OR_SEXUALIZATION', displayName: 'Abuse or Sexualization of a Minor' },
  { reason: 'DOXXING', displayName: "Revealing Other's Personal Information" },
  { reason: 'IMPERSONATION', displayName: 'Impersonation' },
  { reason: 'SELF_HARM', displayName: 'Threatening Self Harm or Suicide' },
  { reason: 'SPAM', displayName: 'Spam' }
];

export const ReportingResourceTypes = {
  COMMENT: 'COMMENT',
  POST: 'POST'
};
