import { Grid } from '@mui/material';

const ErrorLoading = () => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <h2>Oh No!</h2>
        <p>
          It seems there was an error loading this content. If this error persists, please contact
          us!
        </p>
      </Grid>
    </Grid>
  );
};

export default ErrorLoading;
