import { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Alert,
  IconButton,
  AlertTitle,
  Collapse
} from '@mui/material';
import _ from 'underscore';
import { API, Storage } from 'aws-amplify';
import { constants } from '../../../ApiConstants';
import CloseIcon from '@mui/icons-material/Close';
import ALERT_CONSTANTS from '../../alert/AlertConstants';
import { useAuthenticator } from '@aws-amplify/ui-react';

const MusicAddRequest = () => {
  const [fileName, setFileName] = useState('');
  const [movement, setMovement] = useState('');
  const [year, setYear] = useState('');
  const [league, setLeague] = useState('');
  const [groupClass, setGroupClass] = useState('');
  const [group, setGroup] = useState('');
  const [showName, setShowName] = useState('');
  const [instrument, setInstrument] = useState('');
  const [uploadFile, setUploadFile] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const { user } = useAuthenticator((context) => [context.user]);
  const preferredUsername = user?.username;

  const yearSelect = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1950; i--) {
    yearSelect.push({
      value: i,
      tag: i.toString()
    });
  }

  const leagueSelection = [
    {
      value: 'DCI',
      tag: 'DCI'
    },
    {
      value: 'WGI',
      tag: 'WGI'
    },
    {
      value: 'BOA',
      tag: 'BOA'
    }
  ];

  const classSelection = {
    DCI: [
      {
        value: 'World',
        tag: 'World'
      },
      {
        value: 'Open',
        tag: 'Open'
      },
      {
        value: 'All-Age',
        tag: 'All-Age'
      },
      {
        value: 'International',
        tag: 'International'
      }
    ],
    WGI: [
      {
        value: 'Independent World',
        tag: 'Independent World'
      },
      {
        value: 'Independent Open',
        tag: 'Independent Open'
      },
      {
        value: 'Independent A',
        tag: 'Independent A'
      },
      {
        value: 'Scholastic World',
        tag: 'Scholastic World'
      },
      {
        value: 'Scholastic Open',
        tag: 'Scholastic Open'
      },
      {
        value: 'Scholastic A',
        tag: 'Scholastic A'
      },
      {
        value: 'Scholastic Concert World',
        tag: 'Scholastic Concert World'
      },
      {
        value: 'Scholastic Concert Open',
        tag: 'Scholastic Concert Open'
      },
      {
        value: 'Scholastic Concert A',
        tag: 'Scholastic Concert A'
      }
    ],
    BOA: [
      {
        value: 'A',
        tag: 'A'
      },
      {
        value: 'AA',
        tag: 'AA'
      },
      {
        value: 'AAA',
        tag: 'AAA'
      },
      {
        value: 'AAAA',
        tag: 'AAAA'
      }
    ]
  };

  const movementSelect = [
    {
      value: 0,
      tag: 'Full Show'
    },
    {
      value: 1,
      tag: 'Movement One'
    },
    {
      value: 2,
      tag: 'Movement Two'
    },
    {
      value: 3,
      tag: 'Movement Three'
    },
    {
      value: 4,
      tag: 'Movement Four'
    },
    {
      value: 5,
      tag: 'Movement Five'
    }
  ];

  const instrumentSelect = [
    {
      value: 'Snare Drum',
      tag: 'Snare Drum'
    },
    {
      value: 'Tenors',
      tag: 'Tenors'
    },
    {
      value: 'Bass Drums',
      tag: 'Bass Drums'
    },
    {
      value: 'Cymbals',
      tag: 'Cymbals'
    },
    {
      value: 'Drumline',
      tag: 'Drumline'
    },
    {
      value: 'Marimba',
      tag: 'Marimba'
    },
    {
      value: 'Vibraphone',
      tag: 'Vibraphone'
    },
    {
      value: 'Xylopohone',
      tag: 'Xylophone'
    },
    {
      value: 'Glockenspiel',
      tag: 'Glockenspiel'
    },
    {
      value: 'Auxiliary',
      tag: 'Auxiliary'
    },
    {
      value: 'Timpani',
      tag: 'Timpani'
    },
    {
      value: 'Drum Set',
      tag: 'Drum Set'
    },
    {
      value: 'Front Ensemble',
      tag: 'Front Ensemble'
    },
    {
      value: 'Full Ensemble',
      tag: 'Full Ensemble'
    },
    {
      value: 'Other',
      tag: 'Other'
    }
  ];

  const handleMusicAddRequest = async () => {
    let fileKey = '';
    console.log(year, league, groupClass, group, showName, instrument, movement, uploadFile);
    if (
      _.isEmpty(year) ||
      _.isEmpty(league) ||
      _.isEmpty(groupClass) ||
      _.isEmpty(group) ||
      _.isEmpty(showName) ||
      _.isEmpty(instrument) ||
      _.isEmpty(movement)
    ) {
      setAlertMessage(
        'Oops! It looks like there are a few empty required fields, please try again!'
      );
      setAlertType(ALERT_CONSTANTS.ALERT_TYPE.ERROR);
    } else {
      if (uploadFile.size > 2000000) {
        setAlertType(ALERT_CONSTANTS.ALERT_TYPE.ERROR);
        setAlertMessage('File size is too big! Upload files can only be 1MB or smaller!');
        resetForm();
        return;
      }
      const fileName = `${year}-${group}-${showName}-${instrument}-${movement}.pdf`;
      const s3Location = 'musicrequests';
      try {
        fileKey = await Storage.put(`${s3Location}/${fileName}`, uploadFile, {
          level: 'private',
          contentType: 'application/pdf'
        });
      } catch (err) {
        console.warn(`Unable to upload file! Error: ${err}`);
        setAlertMessage(
          "There was an issue processing your music request! It seems we're having issues uploading files right now. Please try again later."
        );
        setAlertType(ALERT_CONSTANTS.ALERT_TYPE.ERROR);
        resetForm();
        return;
      }
      await sendMusicRequest(fileKey.key);
    }
  };

  const sendMusicRequest = async (fileKey) => {
    try {
      await API.post(constants.API_NAME, '/music-request', {
        body: {
          year,
          league,
          groupClass,
          group,
          showName,
          instrument,
          movement,
          submittedBy: preferredUsername,
          fileKey
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((resp) => {
          console.log(resp);
          setAlertMessage('Successfully generated your request!');
          setAlertType('success');
        })
        .catch((err) => {
          console.warn(`Unable to create request! Error: ${err}`);
          setAlertMessage(
            "There was an issue processing your music request! It seems we're having issues with our database right now, please try again later!"
          );
          setAlertType('error');
        });
    } catch (error) {
      setAlertMessage(
        "There was an issue processing your music request! We're unsure what the issue is right now, please try again later!"
      );
      setAlertType('error');
    }
    resetForm();
  };

  const resetForm = () => {
    setFileName('');
    setMovement('');
    setYear('');
    setLeague('');
    setGroupClass('');
    setGroup('');
    setShowName('');
    setInstrument('');
    setUploadFile('');
  };

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  return (
    <>
      <Collapse in={() => _.isEmpty(alertMessage)}>
        <Alert
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
              {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="filled">
          <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
          {alertMessage}
        </Alert>
      </Collapse>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item>
          <h1>Music Add Request</h1>
        </Grid>
        <Grid item>
          <p>
            Fill in the below form with all the correct information to create a music addition
            request
          </p>
        </Grid>
        <Grid item width={200}>
          <FormControl fullWidth>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year-select"
              value={year}
              label="Year"
              onChange={(event) => setYear(event.target.value)}
              error={_.isEmpty(year) && _.isEqual(alertType, 'error')}>
              {yearSelect.map(({ value, tag }) => {
                return (
                  <MenuItem value={tag} key={`year-${value}`}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item width={200}>
          <FormControl fullWidth>
            <InputLabel id="league-label">League</InputLabel>
            <Select
              labelId="league-label"
              id="league-select"
              value={league}
              label="League"
              onChange={(event) => setLeague(event.target.value)}
              error={_.isEmpty(league) && _.isEqual(alertType, 'error')}>
              {leagueSelection.map(({ value, tag }) => {
                return (
                  <MenuItem value={tag} key={`league-${value}`}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item width={200}>
          <FormControl fullWidth>
            <InputLabel id="group-class-label">Class</InputLabel>
            <Select
              labelId="group-class-label"
              id="group-class-select"
              value={groupClass}
              label="Class"
              onChange={(event) => setGroupClass(event.target.value)}
              error={_.isEmpty(groupClass) && _.isEqual(alertType, 'error')}>
              {classSelection[league]?.map(({ value, tag }) => {
                return (
                  <MenuItem value={tag} key={`group-class-${value}`}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            required
            label="Group"
            variant="outlined"
            value={group}
            onChange={(e) => setGroup(e.target.value)}
            error={_.isEmpty(group) && _.isEqual(alertType, 'error')}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            label="Show Name"
            variant="outlined"
            value={showName}
            onChange={(e) => setShowName(e.target.value)}
            error={_.isEmpty(showName) && _.isEqual(alertType, 'error')}
          />
        </Grid>
        <Grid item width={200}>
          <FormControl fullWidth>
            <InputLabel id="instrument-label">Instrument</InputLabel>
            <Select
              labelId="instrument-label"
              id="instrument-select"
              value={instrument}
              label="Instrument"
              onChange={(event) => setInstrument(event.target.value)}
              error={_.isEmpty(instrument) && _.isEqual(alertType, 'error')}>
              {instrumentSelect.map(({ value, tag }) => {
                return (
                  <MenuItem value={tag} key={`movement-${value}`}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item width={200}>
          <FormControl fullWidth>
            <InputLabel id="movement-label">Movement</InputLabel>
            <Select
              labelId="movement-label"
              id="movement-select"
              value={movement}
              label="Movement"
              onChange={(event) => setMovement(event.target.value)}
              error={_.isEmpty(movement) && _.isEqual(alertType, 'error')}>
              {movementSelect.map(({ value, tag }) => {
                return (
                  <MenuItem value={tag} key={`movement-${value}`}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item pt={2}>
          <input
            accept="application/pdf"
            className="file-input-class"
            style={{ display: 'none' }}
            id="file-input"
            type="file"
            onChange={(event) => {
              event.preventDefault();
              setFileName(event.target.files[0].name);
              setUploadFile(event.target.files[0]);
            }}
            required
          />
          <label htmlFor="file-input">
            <Button variant="outlined" component="span">
              Add File
            </Button>
          </label>
        </Grid>
        {fileName ? (
          <Grid item>
            <h5>{fileName}</h5>
          </Grid>
        ) : (
          ''
        )}
        <Grid item>
          <Button type="submit" variant="contained" onClick={handleMusicAddRequest}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MusicAddRequest;
