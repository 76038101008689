import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { PropTypes } from 'prop-types';

const RequireAdminPermissions = ({ children }) => {
  const location = useLocation();
  const { user, route } = useAuthenticator((context) => [context.user]);
  const userPayload = user?.getSignInUserSession()?.getAccessToken().payload;
  const userGroups = userPayload ? userPayload['cognito:groups'] : [];

  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location, redirect: true }} replace />;
  }

  if (!userGroups.includes('Admin')) {
    return <Navigate to="/forbidden" state={{ from: location, redirect: true }} replace />;
  }

  return children;
};

RequireAdminPermissions.propTypes = {
  children: PropTypes.any.isRequired
};

export default RequireAdminPermissions;
