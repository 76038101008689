import { composeWithDevTools } from '@redux-devtools/extension';
import { legacy_createStore as createStore } from 'redux';
import { loadState, syncState } from './persistedState';
import rootReducer from './rootReducer';

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, composeWithDevTools());

store.subscribe(() => {
  syncState(store);
});

export default store;
