const ALERT_CONSTANTS = {
  ALERT_TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info'
  }
};

export default ALERT_CONSTANTS;
