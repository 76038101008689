import { API, Storage } from 'aws-amplify';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Grid,
  CircularProgress,
  Alert,
  IconButton,
  AlertTitle,
  Collapse,
  Card,
  Typography,
  CardContent
} from '@mui/material';
import ErrorLoading from '../../error/ErrorLoading';
import { useEffect, useState } from 'react';
import { constants } from '../../../ApiConstants';

import ALERT_CONSTANTS from '../../alert/AlertConstants';
import _ from 'underscore';
import CloseIcon from '@mui/icons-material/Close';
import CelebrationIcon from '@mui/icons-material/Celebration';

const AdminPage = () => {
  const [requestData, setRequestData] = useState('');
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [requestsLoadError, setRequestsLoadError] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [requestProcessed, setRequestProcessed] = useState(false);

  useEffect(() => {
    API.get(constants.API_NAME, '/requests-vendor').then((r) => {
      setRequestData(r.Items);
    });
    if (requestData.error) {
      setRequestsLoadError(true);
    }
  }, [requestProcessed]);

  useEffect(() => {
    setRequestsLoading(requestData === '');
  }, [requestData]);

  const downloadFile = async (fileName, year, group, instrument, showName) => {
    const fileBlob = await Storage.get(fileName, {
      level: 'private',
      download: true,
      contentType: 'application/pdf'
    });

    const url = window.URL.createObjectURL(fileBlob.Body);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${group}-${year}-${showName}-${instrument}.pdf`;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  };

  const handleRequest = async (fileName, createdAt, requestStatus) => {
    const init = {
      body: {
        requestStatus,
        createdAt
      }
    };
    const response = await API.post(constants.API_NAME, '/music-request-processor', init);
    if (requestStatus === 'denied') {
      setAlertType(ALERT_CONSTANTS.ALERT_TYPE.SUCCESS);
      setAlertMessage('Successfully Denied Request!');
      setRequestProcessed(true);
      return response;
    }
    const newFile = await Storage.copy(
      { key: `${fileName}`, level: 'private' },
      { key: `${fileName.split('/')[1]}`, level: 'public' }
    );
    setAlertType(ALERT_CONSTANTS.ALERT_TYPE.SUCCESS);
    setAlertMessage('Successfully Approved Request!');
    setRequestProcessed(true);
    console.log(newFile);
    return response;
  };

  const renderDownloadButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          onClick={() =>
            downloadFile(
              params.row.fileKey,
              params.row.year,
              params.row.group,
              params.row.instrument,
              params.row.showName
            )
          }>
          Download
        </Button>
      </strong>
    );
  };

  const renderApproveButton = (params) => {
    console.log(params);
    return (
      <strong>
        <Button
          variant="contained"
          style={{ backgroundColor: '#05ba02' }}
          onClick={() => {
            handleRequest(params.row.fileKey, params.row.createdAt, 'approved');
          }}>
          Approve
        </Button>
      </strong>
    );
  };

  const renderDenyButton = (params) => {
    console.log(params);
    return (
      <strong>
        <Button
          variant="contained"
          style={{ backgroundColor: '#d10015', color: 'white' }}
          onClick={() => {
            handleRequest(params.row.fileKey, params.row.createdAt, 'denied');
          }}>
          Deny
        </Button>
      </strong>
    );
  };

  const renderCreatedAt = (params) => {
    return new Date(params.row.createdAt * 1e3).toLocaleDateString();
  };

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Date Requested',
      flex: 1,
      minWidth: 150,
      renderCell: renderCreatedAt
    },
    { field: 'year', headerName: 'Year', minWidth: 75, flex: 1 },
    { field: 'league', headerName: 'League', minWidth: 75, flex: 1 },
    { field: 'groupClass', headerName: 'Class', minWidth: 75, flex: 1 },
    { field: 'group', headerName: 'Group', minWidth: 125, flex: 1 },
    { field: 'instrument', headerName: 'Instrument', minWidth: 100, flex: 1 },
    { field: 'showName', headerName: 'Show Name', minWidth: 150, flex: 1 },
    { field: 'movement', headerName: 'Movement', minWidth: 125, flex: 1 },
    { field: 'submittedBy', headerName: 'Submitted By', minWidth: 125, flex: 1 },
    {
      field: 'fileKey',
      headerName: 'Download',
      minWidth: 130,
      flex: 1,
      renderCell: renderDownloadButton
    },
    {
      field: 'approve',
      headerName: 'Approve',
      minWidth: 115,
      flex: 1,
      renderCell: renderApproveButton
    },
    { field: 'deny', headerName: 'Deny', minWidth: 100, flex: 1, renderCell: renderDenyButton }
  ];

  let requestsComponentToRender;
  if (requestsLoadError) {
    requestsComponentToRender = <ErrorLoading />;
  } else {
    if (requestsLoading) {
      requestsComponentToRender = (
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Grid>
      );
    } else {
      if (_.isEmpty(requestData)) {
        requestsComponentToRender = (
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Card variant="outlined">
              <CardContent>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                  <Typography variant="h4" sx={{ padding: '1rem' }}>
                    Good Job!
                  </Typography>
                  <Grid container direction="row" alignItems="center" justifyContent="center">
                    <CelebrationIcon />
                    <CelebrationIcon />
                    <CelebrationIcon />
                  </Grid>
                  <Typography variant="body" sx={{ padding: '1rem' }}>
                    It looks like there are no more music requests! Congrats!
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      } else {
        requestsComponentToRender = (
          <DataGrid getRowId={(row) => row.createdAt} rows={requestData} columns={columns} />
        );
      }
    }
  }

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  return (
    <>
      <Collapse in={() => _.isEmpty(alertMessage)}>
        <Alert
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
              {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="filled">
          <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
          {alertMessage}
        </Alert>
      </Collapse>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <h1>Music Requests</h1>
        </Grid>
        <Grid item>
          <h4>
            The below table contains all currently processing requests for music on DrumSmith.
          </h4>
        </Grid>
        <Grid item style={{ width: '70%', paddingTop: '2rem' }}>
          {requestsComponentToRender}
        </Grid>
      </Grid>
    </>
  );
};

export default AdminPage;
