import { useEffect, useState } from 'react';
import { API, Auth, Cache } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import * as subscriptions from '../../../graphql/subscriptions';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import _ from 'underscore';
import alertConstants from '../../alert/AlertConstants';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { Navigate, useLocation } from 'react-router-dom';

const Filter = require('bad-words');

const ForumHome = () => {
  const location = useLocation();
  const [topicOpen, setTopicOpen] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [newTopicData, setNewTopicData] = useState({ title: '', content: '' });
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [createInProgress, setCreateInProgress] = useState(false);
  const [topics, setTopics] = useState([]);
  const filter = new Filter();

  useEffect(() => {
    fetchTopics();
    setTopics((topics) => topics.filter((e) => !_.isEmpty(e)));
    const createTopicSubscription = onCreateTopicSubscription();
    const deleteTopicSubscription = onDeleteTopicSubscription();
    console.log(createTopicSubscription);
    console.log(deleteTopicSubscription);
    console.log(topics);
  }, []);

  const onCreateTopicSubscription = () => {
    return API.graphql({
      query: subscriptions.onCreateTopic
    }).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        const item = value.data.onCreateTopic;
        setTopics((topics) => [item, ...topics]);
        const cachedTopics = Cache.getItem('forumTopics');
        if (_.isUndefined(cachedTopics) || _.isNull(cachedTopics)) {
          Cache.setItem('forumTopics', [item], {
            expires: Date.now() + 5 * 60000
          });
        } else {
          cachedTopics.push(item);
          Cache.setItem('forumTopics', cachedTopics, {
            expires: Date.now() + 5 * 60000
          });
        }
      },
      error: (error) => console.error(error)
    });
  };

  const onDeleteTopicSubscription = () => {
    return API.graphql({
      query: subscriptions.onDeleteTopic
    }).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        const item = value.data.onDeleteTopic;
        setTopics((topics) => topics.filter((e) => e.id !== item.id));
        const cachedTopics = Cache.getItem('forumTopics');
        console.log(cachedTopics);
      },
      error: (error) => console.error(error)
    });
  };

  const fetchTopics = async () => {
    const topics = Cache.getItem('forumTopics');
    if (_.isUndefined(topics) || _.isNull(topics)) {
      console.log('Falling back to remote, unable to retrieve entries from Cache');
      try {
        const resp = await API.graphql({
          query: queries.listTopics
        });
        console.log(resp);
        setTopics(resp.data.listTopics.items);
        Cache.setItem('forumTopics', resp.data.listTopics.items, {
          expires: Date.now() + 5 * 60000
        });
      } catch (err) {
        console.error({ err });
      }
    } else {
      setTopics(topics);
    }
  };

  const createTopic = async () => {
    if (_.isUndefined(await Auth.currentUserInfo())) {
      return <Navigate to="/login" state={{ from: location, redirect: true }} replace />;
    }
    setCreateInProgress(true);
    try {
      const newData = {
        title: filter.clean(newTopicData.title),
        content: filter.clean(newTopicData.content)
      };
      const newTopic = await API.graphql({
        query: mutations.createTopic,
        variables: { input: newData }
      });
      console.log(newTopic);
      setTopics((topics) => [newTopic.data.createTopic, ...topics]);
      const cachedTopics = Cache.getItem('forumTopics');
      if (_.isUndefined(cachedTopics) || _.isNull(cachedTopics)) {
        Cache.setItem('forumTopics', [newTopic.data.createTopic], {
          expires: Date.now() + 5 * 60000
        });
      } else {
        cachedTopics.push(newTopic.data.createTopic);
        Cache.setItem('forumTopics', cachedTopics, {
          expires: Date.now() + 5 * 60000
        });
      }
      setAlertType(alertConstants.ALERT_TYPE.SUCCESS);
      setAlertMessage('Successfully created new topic!');
      setNewTopicData({ title: '', content: '' });
    } catch (err) {
      console.error({ err });
      setAlertType(alertConstants.ALERT_TYPE.ERROR);
      setAlertMessage(`Unable to create new topic! ${err}`);
    }
    setCreateInProgress(false);
  };

  const handleTopicFormChange = (e) => {
    setNewTopicData({ ...newTopicData, [e.target.id]: e.target.value });
  };

  const createTopicForm = (
    <Dialog open={topicOpen} onClose={() => setTopicOpen(false)} maxWidth={false}>
      <DialogTitle sx={{ marginBottom: '2rem' }}>
        <strong>Create New Thread</strong>
      </DialogTitle>
      <DialogContent sx={{ width: '50vw' }}>
        <Grid
          container
          direction="column"
          alignItems="left"
          justifyContent="left"
          spacing={4}
          fullWidth>
          <Grid item>
            <TextField
              fullWidth
              required
              id="title"
              label="Title"
              variant="outlined"
              value={newTopicData.title}
              onChange={handleTopicFormChange}
              error={
                _.isEmpty(newTopicData.title) &&
                _.isEqual(alertType, alertConstants.ALERT_TYPE.ERROR)
              }
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              multiline
              id="content"
              label="Content"
              variant="outlined"
              value={newTopicData.content}
              onChange={handleTopicFormChange}
              error={
                _.isEmpty(newTopicData.content) &&
                _.isEqual(alertType, alertConstants.ALERT_TYPE.ERROR)
              }
              rows={4}
            />
          </Grid>
          <Grid item>
            <Button
              disable={
                createInProgress || _.isEmpty(newTopicData.title) || _.isEmpty(newTopicData.content)
              }
              type="submit"
              variant="contained"
              onClick={createTopic}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  let rules = (
    <Dialog open={rulesOpen} onClose={() => setRulesOpen(false)} maxWidth={false}>
      <DialogTitle>
        <strong>DrumSmith Forum Rules</strong>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ width: '60vw' }}>
        <List sx={{ listStyle: 'decimal', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="Be Respectful"
              secondary="Treat others with kindness and respect. No personal attacks, insults, or offensive language. Discrimination based on race, gender, religion, nationality, or any other personal characteristic WILL NOT be tolerated."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="No Spamming"
              secondary="Do not post promotional content, advertisements, or unrelated links excessively and refrain from excessive self-promotion."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="No Illegal or Harmful Content"
              secondary="Do not share or promote any illegal or harmful content, including but not limited to pirated software, viruses, or any form of harassment."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="Respect Privacy"
              secondary="Do not overly share personal information about yourself or others. Respect the privacy of fellow community members."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="No Trolling or Flame Wars"
              secondary="Do not engage in trolling or flame wars. Disagreements are acceptable, but keep discussions civil and constructive."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="Use Appropriate Language"
              secondary="Refrain from using profanity. Remember that the forum may be accessed by users of all ages."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="Follow Moderator Instructions"
              secondary="Follow instructions from forum moderators. Report any issues or violations promptly."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="Provide Constructive Feedback"
              secondary="When providing feedback to fellow community members, keep it constructive. Avoid personal attacks."
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary="Have Fun!"
              secondary="Remember to have fun! This forum is meant to be a place where all members of our community can generate or participate in discussion, let's make sure to have fun while doing so!"
            />
          </ListItem>
        </List>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ width: '60vw' }}>
        Failure to follow these rules can and <strong>WILL</strong> result in a Ban from the Forums
        and potentially DrumSmith. If you have any questions about, or want to make suggestions for,
        these rules, please reach out to a Site Admin! Thanks!
      </DialogContent>
    </Dialog>
  );

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  const renderTopicLink = (params) => {
    return <Link href={`/forum/topic/${params.row.id}`}>{params.row.title}</Link>;
  };

  const renderCreatedAt = (params) => {
    return new Date(params.row.createdAt).toLocaleString().replaceAll(',', '');
  };

  const columnVisibility = {
    id: false
  };

  const topicColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'title', headerName: 'Topic', flex: 1, renderCell: renderTopicLink, minWidth: 175 },
    { field: 'owner', headerName: 'Submitted By', flex: 1, minWidth: 150 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      renderCell: renderCreatedAt,
      minWidth: 175
    }
  ];

  return (
    <>
      <Collapse in={() => _.isEmpty(alertMessage)}>
        <Alert
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
              {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="filled">
          <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
          {alertMessage}
        </Alert>
      </Collapse>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item>
          <Typography variant="h4">DrumSmith Forums</Typography>
        </Grid>
        <Grid item>
          <Grid container item direction="row" alignItems="left" justifyContent="left">
            <Grid item xs={6}>
              <Button variant="contained" onClick={() => setTopicOpen(true)}>
                New Thread
              </Button>
            </Grid>
            <Grid item container justifyContent="right" xs={6}>
              <Button variant="contained" onClick={() => setRulesOpen(true)}>
                Rules
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            columns={topicColumns}
            columnVisibilityModel={columnVisibility}
            rows={topics}
            disableRowSelectionOnClick
            style={{ width: '75vw', marginTop: '2rem', marginBottom: '2rem' }}
          />
        </Grid>
      </Grid>
      {createTopicForm}
      {rules}
    </>
  );
};

export default ForumHome;
