import { CardContent, Grid, Card } from '@mui/material';

function Home() {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <h1>Welcome to DrumSmith!</h1>
      </Grid>
      <Grid item>
        <h3>The Modern Home of Free Marching Percussion Resources</h3>
      </Grid>
      <Grid container spacing={2} direction="row" alignItems="top" justifyContent="center">
        <Grid item>
          <Card variant="outlined" sx={{ maxWidth: 350 }}>
            <CardContent>
              <h3>About Us</h3>
              <p>
                A spiritual successor to <a href="http://www.snarescience.com">Snare Science</a>,
                This website was built to provide a way for people to easily find resources to
                better their marching percussion abilities, as well as a transcription database to
                help archive the music of past, present, and future drum corps. This website is
                actively undergoing development with a roadmap to implement multiple features such
                as a Forum, Exercise Database, and more!
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card variant="outlined" sx={{ maxWidth: 350 }}>
            <CardContent>
              <h3>The Goal</h3>
              <p>
                To provide resources for students and professionals to continually improve their
                marching percussion capabilities. By doing so, I hope to further the activity and
                help students and professionals achieve their full potentials.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card variant="outlined" sx={{ maxWidth: 350 }}>
            <CardContent>
              <h3>How to Add Music</h3>
              <p>
                You can add music by going to <a href="/music-database/add-request">this link!</a>{' '}
                Music addition requests must be approved by site admins, so it may take a few hours
                to a few days for your request to be processed. To apply to be an Admin, fill out
                the admin application <a href="/admin-application">here!</a>
              </p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Home;
