import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import _ from 'underscore';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import alertConstants from '../../alert/AlertConstants';
import { API } from 'aws-amplify';
import { constants } from '../../../ApiConstants';

const AdminApplicationPage = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [previousAdmin, setPreviousAdmin] = useState(false);
  const [previousAdminMessage, setPreviousAdminMessage] = useState('');
  const [whyAdminMessage, setWhyAdminMessage] = useState('');
  const [musicExperienceMessage, setMusicExperienceMessage] = useState('');
  const { user } = useAuthenticator((context) => [context.user]);
  const email = user?.attributes?.email;
  const username = user?.username;

  const resetForm = () => {
    setPreviousAdminMessage('');
    setWhyAdminMessage('');
    setPreviousAdmin(false);
  };

  const handleAdminApplicationRequest = async () => {
    if (previousAdmin) {
      if (_.isEmpty(previousAdminMessage) || _.isEmpty(whyAdminMessage)) {
        setAlertType(alertConstants.ALERT_TYPE.ERROR);
        setAlertMessage(
          'Oops! It looks like there are a few empty required fields, please try again!'
        );
      }
    } else {
      if (_.isEmpty(whyAdminMessage)) {
        setAlertType(alertConstants.ALERT_TYPE.ERROR);
        setAlertMessage(
          'Oops! It looks like there are a few empty required fields, please try again!'
        );
      }
    }

    try {
      await API.post(constants.API_NAME, '/admin-request', {
        body: {
          username,
          email,
          previousAdminExperience: previousAdmin,
          previousAdminExperienceMessage: previousAdminMessage,
          whyAdminMessage,
          previousMusicalExperience: musicExperienceMessage
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((r) => {
        console.log(r);
        setAlertMessage('Successfully submitted your application!');
        setAlertType(alertConstants.ALERT_TYPE.SUCCESS);
      });
    } catch (e) {
      setAlertMessage(
        'There was an issue processing your admin application! Please try again later!'
      );
      setAlertType(alertConstants.ALERT_TYPE.ERROR);
    }

    setAlertType(alertConstants.ALERT_TYPE.SUCCESS);
    setAlertMessage('Application has been submitted succesfully!');
    resetForm();
  };

  let previousAdminForm = previousAdmin ? (
    <Grid item width={350}>
      <TextField
        fullWidth
        required
        multiline
        onChange={(e) => setPreviousAdminMessage(e.target.value)}
        label="What is your previous experience?"
        variant="outlined"
        error={_.isEmpty(whyAdminMessage) && _.isEqual(alertType, alertConstants.ALERT_TYPE.ERROR)}
        rows={4}
      />
    </Grid>
  ) : (
    ''
  );

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  return (
    <>
      <Collapse in={() => _.isEmpty(alertMessage)}>
        <Alert
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
              {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="filled">
          <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
          {alertMessage}
        </Alert>
      </Collapse>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          <Typography variant="h4">Admin Application</Typography>
        </Grid>
        <Grid item>
          <Typography variant="p">
            Fill in the information below to apply to become an Admin! Applications are reviewed on
            a bi-weekly cadence and you will receive an email when your application has been
            processed
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={previousAdmin}
                onChange={() => {
                  setPreviousAdmin(!previousAdmin);
                }}
              />
            }
            label="Previous Admin Experience?"
          />
        </Grid>
        {previousAdminForm}
        <Grid item width={350}>
          <TextField
            fullWidth
            required
            multiline
            onChange={(e) => setWhyAdminMessage(e.target.value)}
            label="Why do you want to be an Admin?"
            variant="outlined"
            error={
              _.isEmpty(whyAdminMessage) && _.isEqual(alertType, alertConstants.ALERT_TYPE.ERROR)
            }
            rows={4}
          />
        </Grid>
        <Grid item width={350}>
          <TextField
            fullWidth
            required
            multiline
            onChange={(e) => setMusicExperienceMessage(e.target.value)}
            label="What musical experience do you have?"
            variant="outlined"
            error={
              _.isEmpty(musicExperienceMessage) &&
              _.isEqual(alertType, alertConstants.ALERT_TYPE.ERROR)
            }
            rows={4}
          />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" onClick={handleAdminApplicationRequest}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminApplicationPage;
