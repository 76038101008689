import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import * as queries from '../../../graphql/queries';
import { API, Cache } from 'aws-amplify';
import ForumComment from './ForumComment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'underscore';
import alertConstants from '../../alert/AlertConstants';
import CloseIcon from '@mui/icons-material/Close';
import * as mutations from '../../../graphql/mutations';
import * as subscriptions from '../../../graphql/subscriptions';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { cleanString } from '../../../utilities/CensoringUtility';
import { ReportingResourceTypes } from '../../../constants/ReportingConstants';
import ReportingModal from './ReportingModal';

const ForumTopic = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [topic, setTopic] = useState({});
  const [newCommentData, setNewCommentData] = useState({ content: '' });
  const [comments, setComments] = useState([]);
  const [commentNextToken, setCommentNextToken] = useState();
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [modalAlertType, setModalAlertType] = useState('');
  const [modalAlertMessage, setModalAlertMessage] = useState('');
  const [createInProgress, setCreateInProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editContent, setEditContent] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportingReason, setReportingReason] = useState('');
  const [reportingContext, setReportingContext] = useState('');

  const { user } = useAuthenticator((context) => [context.user]);
  const userPayload = user?.getSignInUserSession()?.getAccessToken().payload;
  const userGroups = userPayload ? userPayload['cognito:groups'] : [];
  const isUserAdmin = userGroups?.includes('Admin');

  useEffect(() => {
    const createCommentSubscription = onCreateCommentSubscription();
    const deleteCommentSubscription = onDeleteCommentSubscription();
    const updateCommentSubscription = onUpdateCommentSubscription();
    fetchTopic();
    console.log(comments);
    console.log(commentNextToken);
    console.log(createCommentSubscription);
    console.log(deleteCommentSubscription);
    console.log(updateCommentSubscription);
  }, []);

  const onCreateCommentSubscription = () => {
    return API.graphql({
      query: subscriptions.onCreateCommentByTopicId,
      variables: {
        topicId: id
      }
    }).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        const item = value.data.onCreateCommentByTopicId;
        console.log('New Comment: ', item);
        setComments((comments) => {
          if (_.isUndefined(comments)) {
            return [item];
          } else {
            return [item, ...comments];
          }
        });
      },
      error: (error) => console.error(error)
    });
  };

  const onDeleteCommentSubscription = () => {
    return API.graphql({
      query: subscriptions.onDeleteCommentByTopicId,
      variables: {
        topicId: id
      }
    }).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        const item = value.data.onDeleteCommentByTopicId;
        console.log('Deleted Comment: ', item);
        setComments((comments) => comments.filter((e) => e.id !== item.id));
        const cachedComments = Cache.getItem('cachedComments') || {};
        if (Object.prototype.hasOwnProperty.call(cachedComments, id)) {
          let cachedCommentsArray = cachedComments[id].comments;
          cachedCommentsArray = cachedCommentsArray.filter((e) => e.id !== item.id);
          cachedComments[id].comments = cachedCommentsArray;
          console.log(cachedComments);
          Cache.setItem('cachedComments', cachedComments, { expires: Date.now() + 5 * 60000 });
        }
      },
      error: (error) => console.error(error)
    });
  };

  const onUpdateCommentSubscription = () => {
    return API.graphql({
      query: subscriptions.onUpdateCommentByTopicId,
      variables: {
        topicId: id
      }
    }).subscribe({
      next: async ({ provider, value }) => {
        console.log({ provider, value });
        const item = value.data.onUpdateCommentByTopicId;
        console.log('Updated Comment: ', JSON.stringify(item));
        let cachedComments = Cache.getItem('cachedComments');
        if (_.isUndefined(cachedComments) || _.isNull(cachedComments)) {
          cachedComments = await fetchComments();
          Cache.setItem('cachedComments', cachedComments, {
            expires: Date.now() + 5 * 60000
          });
        }
        if (Object.prototype.hasOwnProperty.call(cachedComments, id)) {
          let cachedCommentsArray = cachedComments[id].comments;
          cachedCommentsArray = cachedCommentsArray.filter((e) => e.id !== item.id);
          cachedCommentsArray.push(item);
          cachedComments[id].comments = cachedCommentsArray;
          console.log(cachedComments);
          Cache.setItem('cachedComments', cachedComments, { expires: Date.now() + 5 * 60000 });
        }
        const newComments = comments.filter((e) => e.id !== item.id);
        console.log(`New comments: ${JSON.stringify(newComments)}`);
        newComments.push(item);
        setComments(newComments);
      },
      error: (error) => console.error(error)
    });
  };

  const fetchComments = async () => {
    console.log('Calling commentsByTopicId');
    const resp = await API.graphql({
      query: queries.commentsByTopicId,
      variables: { topicId: id }
    });
    return resp.data.commentsByTopicId.items;
  };

  const fetchTopics = async () => {
    console.log('Calling listTopics');
    const resp = await API.graphql({
      query: queries.listTopics
    });
    return resp.data.listTopics.items;
  };

  const getAndCacheUnCachedTopic = async () => {
    let cachedTopics = Cache.getItem('forumTopics');
    if (_.isUndefined(cachedTopics) || _.isNull(cachedTopics)) {
      cachedTopics = await fetchTopics();
      Cache.setItem('forumTopics', cachedTopics, { expires: Date.now() + 5 * 60000 });
    }
    let cachedTopic = cachedTopics.find((e) => e.id === id);
    if (_.isUndefined(cachedTopic) || _.isEmpty(cachedTopic)) {
      console.log(`Calling GraphQL for un-cached Topic: ${id}`);
      cachedTopic = await API.graphql({
        query: queries.getTopic,
        variables: { id }
      });
      console.log(cachedTopic);
      if (!cachedTopics.find((e) => e.id === cachedTopic.data.getTopic.id)) {
        cachedTopics.push(cachedTopic.data.getTopic);
        Cache.setItem('forumTopics', cachedTopics, {
          expires: Date.now() + 5 * 60000
        });
      }
    }
    return cachedTopic;
  };

  const fetchTopic = async () => {
    const cachedTopic = await getAndCacheUnCachedTopic();
    setTopic(cachedTopic);
    let cachedCommentData = Cache.getItem('cachedComments')?.[id];
    if (_.isUndefined(cachedCommentData) || _.isEmpty(cachedCommentData)) {
      console.log('Calling GraphQL for Comments that are not cached');
      cachedCommentData = await API.graphql({
        query: queries.commentsByTopicId,
        variables: { topicId: id }
      });
      const newComments = cachedCommentData.data.commentsByTopicId.items;
      const newCommentToken = cachedCommentData.data.commentsByTopicId.nextToken;
      const cachedCommentsMap = Cache.getItem('cachedComments');
      if (_.isUndefined(cachedCommentsMap) || _.isEmpty(cachedCommentsMap)) {
        const map = {};
        map[id] = { comments: newComments, nextToken: newCommentToken };
        Cache.setItem('cachedComments', map, {
          expires: Date.now() + 5 * 60000
        });
      } else {
        cachedCommentsMap[id] = { comments: newComments, nextToken: newCommentToken };
        Cache.setItem('cachedComments', cachedCommentsMap, {
          expires: Date.now() + 5 * 60000
        });
      }
      setComments(newComments);
      setCommentNextToken(newCommentToken);
    } else {
      setComments(cachedCommentData.comments);
      setCommentNextToken(cachedCommentData.nextToken);
    }
  };

  const editTopic = async () => {
    if (_.isEmpty(editContent)) {
      return;
    }
    const editedTopic = await API.graphql({
      query: mutations.updateTopic,
      variables: { input: { id, content: cleanString(editContent) } }
    });
    console.log(editedTopic.data.updateTopic);
    setTopic(editedTopic.data.updateTopic);
    let cachedTopics = Cache.getItem('forumTopics');
    if (_.isUndefined(cachedTopics) || _.isNull(cachedTopics)) {
      cachedTopics = await fetchTopics();
      Cache.setItem('forumTopics', cachedTopics, { expires: Date.now() + 5 * 60000 });
    }
    cachedTopics = cachedTopics.filter((e) => e.id !== id);
    cachedTopics.push(editedTopic.data.updateTopic);
    Cache.setItem('forumTopics', cachedTopics, { expires: Date.now() + 5 * 60000 });
    setEditMode(false);
  };

  const deleteTopic = async () => {
    const deletedTopic = await API.graphql({
      query: mutations.deleteTopic,
      variables: { input: { id } }
    });
    console.log(deletedTopic);
    setTopic({});
    let cachedTopics = Cache.getItem('forumTopics');
    if (_.isUndefined(cachedTopics) || _.isNull(cachedTopics)) {
      cachedTopics = await fetchTopics();
      Cache.setItem('forumTopics', cachedTopics, { expires: Date.now() + 5 * 60000 });
    }
    cachedTopics = cachedTopics.filter((e) => e.id !== id);
    Cache.setItem('forumTopics', cachedTopics, { expires: Date.now() + 5 * 60000 });
    setDeleteModalOpen(false);
    navigate('/forum');
  };

  const createNewComment = async () => {
    setCreateInProgress(true);
    try {
      const newComment = {
        content: cleanString(newCommentData.content)
      };
      const newData = await API.graphql({
        query: mutations.createComment,
        variables: { input: { ...newComment, topicId: id } }
      });
      console.log(newData);
      setAlertType(alertConstants.ALERT_TYPE.SUCCESS);
      setAlertMessage('Successfully added a comment!');
      setNewCommentData({ content: '' });
    } catch (err) {
      console.error({ err });
      setAlertType(alertConstants.ALERT_TYPE.ERROR);
      setAlertMessage('Oops! Something went wrong... please try again!');
    }
    setCreateInProgress(false);
  };

  const reportTopic = async () => {
    if (_.isEmpty(reportingReason)) {
      setModalAlertType(alertConstants.ALERT_TYPE.ERROR);
      setModalAlertMessage('Unable to report topic without a reason! Please try again!');
      return;
    }
    try {
      const report = await API.graphql({
        query: mutations.createReport,
        variables: {
          input: {
            resourceId: id,
            resourceType: ReportingResourceTypes.POST,
            reason: reportingReason,
            context: reportingContext
          }
        }
      });
      console.log(report);
      setModalAlertType(alertConstants.ALERT_TYPE.SUCCESS);
      setModalAlertMessage('Successfully Reported Comment!');
    } catch (e) {
      setModalAlertType(alertConstants.ALERT_TYPE.ERROR);
      setModalAlertMessage('Unable to report comment! Please try again!');
      console.error(e);
    }
  };

  const handleCommentFormChange = (e) => {
    setNewCommentData({ ...newCommentData, [e.target.id]: e.target.value });
  };

  const commentForm = (
    <>
      <Grid
        container
        direction="column"
        alignItems="left"
        justifyContent="center"
        sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
        <Grid item>
          <TextField
            fullWidth
            required
            id="content"
            variant="outlined"
            value={newCommentData.content}
            onChange={handleCommentFormChange}
            placeholder="New Comment"
            error={
              _.isEmpty(newCommentData.content) &&
              _.isEqual(alertType, alertConstants.ALERT_TYPE.ERROR)
            }
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid container item justifyContent="right" alignItems="right">
          <Button
            disable={createInProgress || _.isEmpty(newCommentData.content)}
            onClick={createNewComment}
            sx={{ marginTop: '1rem' }}>
            Reply
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const commentsToRender = (
    <Grid item>
      {_.isUndefined(comments) || comments.length === 0 ? (
        commentForm
      ) : (
        <>
          {commentForm}
          {comments.map((comment, commentIdx) => (
            <ForumComment comment={comment} key={commentIdx} />
          ))}
        </>
      )}
    </Grid>
  );

  let editButton = '';
  if (_.isEqual(user.username, topic.owner)) {
    if (editMode) {
      editButton = (
        <>
          <Grid item>
            <strong>
              <Button onClick={editTopic}>Submit</Button>
            </strong>
          </Grid>
          <Grid item>
            <strong>
              <Button
                onClick={() => {
                  setEditMode(false);
                }}>
                Cancel
              </Button>
            </strong>
          </Grid>
        </>
      );
    } else {
      editButton = (
        <Grid item>
          <strong>
            <Button
              onClick={() => {
                setEditMode(true);
                setEditContent(topic.content);
              }}>
              Edit
            </Button>
          </strong>
        </Grid>
      );
    }
  }

  let deleteButton = '';
  if (isUserAdmin || _.isEqual(user.username, topic.owner)) {
    deleteButton = (
      <Grid item>
        <strong>
          <Button onClick={() => setDeleteModalOpen(true)}>Delete</Button>
        </strong>
      </Grid>
    );
  }

  let reportButton = (
    <Grid item>
      <strong>
        <Button onClick={() => setReportModalOpen(true)}>Report</Button>
      </strong>
    </Grid>
  );
  if (_.isEqual(user.username, topic.owner)) {
    reportButton = '';
  }

  let availablePostActions = (
    <>
      {editButton}
      {deleteButton}
      {reportButton}
    </>
  );

  const removeAlert = () => {
    setAlertType('');
    setAlertMessage('');
  };

  const removeModalAlert = () => {
    setModalAlertType('');
    setModalAlertMessage('');
  };

  let topicText = <Typography variant="paragraph">{topic.content}</Typography>;
  if (editMode) {
    topicText = (
      <TextField
        fullWidth
        required
        multiline
        variant="outlined"
        value={editContent}
        onChange={(e) => setEditContent(e.target.value)}
        error={_.isEmpty(editContent)}
        sx={{ marginRight: '1rem' }}
        rows={6}
      />
    );
  }

  let modalContent = '';
  if (deleteModalOpen) {
    modalContent = (
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        maxWidth={false}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          You are about to permanently delete this topic! This action is not reversible... Are you
          sure?
        </DialogContent>
        <DialogContent>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
              <strong>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#05ba02' }}
                  onClick={() => {
                    deleteTopic();
                    setDeleteModalOpen(false);
                  }}>
                  Yes
                </Button>
              </strong>
            </Grid>
            <Grid item>
              <strong>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#d10015', color: 'white' }}
                  onClick={() => setDeleteModalOpen(false)}>
                  No
                </Button>
              </strong>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
  if (reportModalOpen) {
    modalContent = (
      <ReportingModal
        modalTitle="Report Topic"
        modalContent="What would you like to report this topic for?"
        reportingReason={reportingReason}
        reportingContext={reportingContext}
        onReportingReasonChange={(e) => setReportingReason(e.target.value)}
        onReportingContextChange={(e) => setReportingContext(e.target.value)}
        open={reportModalOpen}
        onClose={() => {
          setReportModalOpen(false);
          setReportingReason('');
          setReportingContext('');
        }}
        onReport={() => {
          reportTopic();
          setTimeout(() => {
            setReportModalOpen(false);
            setReportingReason('');
            setReportingContext('');
            removeModalAlert();
          }, 3000);
        }}
        alertType={modalAlertType}
        alertMessage={modalAlertMessage}
        removeAlert={removeModalAlert}
      />
    );
  }

  return (
    <>
      <Collapse in={() => _.isEmpty(alertMessage)}>
        <Alert
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={removeAlert}>
              {!_.isEmpty(alertMessage) ? <CloseIcon fontSize="inherit" /> : ''}
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="filled">
          <AlertTitle>{`${alertType.charAt(0).toUpperCase()}${alertType.slice(1)}`}</AlertTitle>
          {alertMessage}
        </Alert>
      </Collapse>
      {modalContent}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
        sx={{ paddingLeft: '2rem' }}>
        <Button variant="contained" onClick={() => navigate('/forum')}>
          <ArrowBackIcon />
          &nbsp;Back
        </Button>
      </Grid>
      <Container maxWidth="md">
        <Box
          sx={{
            borderRadius: '1rem',
            border: 1,
            paddingTop: '2rem',
            paddingBottom: '2rem',
            marginTop: '2rem',
            marginBottom: '2rem'
          }}>
          <Grid
            container
            direction="column"
            alignItems="left"
            justifyContent="left"
            spacing={3}
            sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
            <Grid container item direction="column" spacing={0}>
              <Grid item>
                <Typography variant="h4">{topic.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Submitted by {topic.owner}</Typography>
              </Grid>
            </Grid>
            <Grid item>{topicText}</Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="right"
              alignItems="right"
              sx={{ paddingRight: '1rem', paddingBottom: '0' }}
              spacing={2}>
              {availablePostActions}
            </Grid>
          </Grid>
        </Box>
        <Grid container direction="column" alignItems="left" justifyContent="center" spacing={3}>
          <Grid container item justifyContent="left" alignItems="left">
            <Typography variant="h5" sx={{ paddingLeft: '2rem' }}>
              Comments ({comments.length})
            </Typography>
          </Grid>
          <Grid item>{commentsToRender}</Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ForumTopic;
