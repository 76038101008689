import { Grid } from '@mui/material';

const Forbidden = () => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <h1>Access Forbidden!</h1>
      </Grid>
      <Grid item>
        <p>It looks like you&apos;re trying to access a page you don&apos;t have permissions to!</p>
      </Grid>
    </Grid>
  );
};

export default Forbidden;
