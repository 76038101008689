const Filter = require('bad-words');
const filter = new Filter();

const addedWords = ['dike'];
const removedWords = ['balls'];

filter.addWords(...addedWords);
filter.removeWords(...removedWords);

export const cleanString = (phrase) => {
  return filter.clean(phrase);
};
